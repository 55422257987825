import { Injectable } from '@angular/core';

import { Store } from '../../state/store';
import { CookiesService } from '../common/cookies.service';

import {
  ActionModalEventDescriptor,
  BusinessEvent, CKEditorEventDescriptor,
  DocManagerEventDescriptor,
  Event,
  EventDescription
} from './event.interfaces';
import { EnvInfoService } from '../common/env-info.service';

import { UIDGenerator } from '../../common/utility-components/uidGenerator';
import { UserAgentService } from '../common/user-agent.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TrackingPublisher } from '../../modules/tracking/publisher';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
  EventPayloadFor,
  isAction,
  isCkeditorCommand, TrackingEvent,
  type TrackingEventType
} from '../../modules/tracking/event-types';
import { Observable } from 'rxjs';

@Injectable()

export class NibblerBusinessEventsService {
  uidgen3: any;
  $events: Observable<TrackingEvent>;

  constructor(private http: HttpClient,
              private cookiesService: CookiesService,
              private store: Store,
              private envInfo: EnvInfoService,
              private userAgentService: UserAgentService,
              private publisher: TrackingPublisher
  ) {
    this.uidgen3 = new UIDGenerator(64, UIDGenerator.BASE16);
  }

  initEventsTracking() {
    if(!this.$events) {
      this.$events = this.publisher.events$.pipe(distinctUntilChanged());
      this.$events.pipe(filter(isAction)).subscribe(event => this.action(event));
      this.$events.pipe(filter(isCkeditorCommand)).subscribe(event => this.ckeditorCommand(event));
    }
  }

  // If there is not coordinating partnered backend service call, pass null for partneredRequestHeaders
  // Develop should intentionally make this null to ignore for things like view page calls
  // If the event coordinates with another backend, partneredRequestHeaders is required
  postBusinessEvent(businessEvent: BusinessEvent, partneredRequestHeaders: HttpHeaders) {
    businessEvent.attemptNumber++;
    businessEvent.event.eventTimestamp = new Date().toISOString();

    // exists & !null
    if (partneredRequestHeaders != void 0) {
      businessEvent.trace = { requestId: partneredRequestHeaders.get('request-id') };
    }

    const headers = {
      'Content-Type': 'application/json',
      'skipAuth': 'true'
    };
    let url = `${this.envInfo.getExternalBackendsApiHost()}/nibbler/v1/events`;
    let body = JSON.stringify(businessEvent);
    return this.http.post(url, body, { headers }).subscribe();
  }

  private action(event: EventPayloadFor<TrackingEventType.Action>) {
    const businessEvent = this.createBusinessEvent({
      eventType: 'NDM_MODAL_BUTTON_CLICKED',
      actionId: event.parameters.id,
      binder: event.binder as DocManagerEventDescriptor['binder'],
      party: event.actingParty as DocManagerEventDescriptor['party']
    } as ActionModalEventDescriptor, {
      market: event.binder.country as string
    });
    return this.postBusinessEvent(businessEvent, null);
  }

  private ckeditorCommand(event: EventPayloadFor<TrackingEventType.CkeEditorCommand>) {
    const businessEvent = this.createBusinessEvent({
      eventType: 'NDM_ADVANCED_EDITOR_BUTTON_CLICKED',
      buttonId: event.parameters.buttonId,
      binder: event.binder as DocManagerEventDescriptor['binder'],
      party: event.actingParty as DocManagerEventDescriptor['party']
    } as CKEditorEventDescriptor, {
      market: event.binder.country as string
    });
    return this.postBusinessEvent(businessEvent, null);
  }

  /* Created for every attempt, wraps an Event
   * To use this, create a new EventDescription type in event.interfaces that extends EventDescription
   * Then pass the new object here as JSON { eventType: 'type', event description specifics here }
   *
   * Pass in overrides if there is a need for it, ex:
   * createBusinessEvent(eventDescriptor, { market: this.currentDocument.interviewRegion});
   */
  createBusinessEvent(eventDescription: EventDescription, optionalOverrides: Record<string, string> = {}): BusinessEvent {
    let event = this.createEvent(eventDescription);
    let descriptorStub = {
      market: 'us',
      serviceName: 'rl-docman-app',
      event: event,
      attemptId: this.uidgen3.generateSync(),
      // First attempt is 1, incremented before each call
      attemptNumber: 0
    };
    Object.keys(optionalOverrides).forEach((key: string) => {
      // If the value is undefined or null, retain value
      descriptorStub[key] = optionalOverrides[key] || descriptorStub[key];
    });
    return descriptorStub;
  }

  createEvent(eventDescription: EventDescription): Event {
    const state = this.store.getState();
    const tokenAuthInfo = state.authInfo;
    const serviceTokenData = tokenAuthInfo ? tokenAuthInfo.serviceData : null;
    const originalClientId = serviceTokenData ? serviceTokenData.originalClientId : null;

    if (eventDescription.eventType == void 0) {
      console.log('Event type is required for events');
      return null;
    }

    return {
      sharedSessionId: this.cookiesService.getSessionId(),
      browserSessionId: this.cookiesService.getBrowserId(),
      referrerUrl: document.referrer,
      clientId: originalClientId || this.envInfo.getAuthClientId(),
      pageUrl: window.location.href.replace(window.location.hash, ''),
      userAgent: this.userAgentService.getUserAgent(),

      eventDescription: eventDescription
    };
  }
}
